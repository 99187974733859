import React, { useState } from "react";
import { Ellipsis } from "react-bootstrap/esm/PageItem";
import ScrollMenu from "react-horizontal-scrolling-menu";
import MenuItem from "../../components/homeItem/MenuItem";
import DataManager, { ICategory } from "../../utils/DataManager";

function HomePage() {
  const [selected, setSelected] = useState("");
  const onSelect = (key: string) => {
    setSelected(key);
  };

  const Menu = (list: ICategory[], selected?: string) =>
    list.map((el) => {
      return <MenuItem item={el} />;
    });

  const datas = Menu(DataManager.categories, undefined);
  return (
    <div className="HomePage">
      <ScrollMenu data={datas} selected={selected} />
    </div>
  );
}

export default HomePage;
