interface IRoute {
  pathName: string;
  description: string;
}

export interface ICategory {
  id: string;
  defaultName: string;
  images: string[];
  path: string;
  subCategories: ISubCategory[];
}

interface ISubCategory {
  id: string;
  defaultName: string;
  path: string;
}

/// @todo
// Possible refactoring -> set this data into json configuration file

class DataManager {
  primaryColor: string = "#A31B43";
  blackColor: string = "#1D1F2A";

  // Sites route
  routes: IRoute[] = [
    { pathName: "/", description: "homepage" },
    { pathName: "/menu", description: "menu" },
    { pathName: "/contacts", description: "contacts" },
  ];

  categories: ICategory[] = [
    {
      id: "category.home.tech",
      defaultName: "Tech",
      images: ["tech"],
      path: "#",
      subCategories: [
        {
          id: "category.home.tech.3dworld",
          defaultName: "3D World",
          path: "/tech/3d-world",
        },
        {
          id: "category.home.tech.appweb",
          defaultName: "App & Web",
          path: "/tech/app-web",
        },
        {
          id: "category.home.tech.internetworld",
          defaultName: "Internet World",
          path: "/tech/internet-world",
        },
        {
          id: "category.home.tech.ai",
          defaultName: "AI",
          path: "/tech/ai",
        },
      ],
    },
    {
      id: "category.home.consulting",
      defaultName: "Consulting",
      images: ["consulting"],
      path: "#",
      subCategories: [],
    },
    {
      id: "category.home.projects",
      defaultName: "Projects",
      images: ["projects"],
      path: "#",
      subCategories: [],
    },
  ];
}

export default new DataManager();
