import React from "react";
import "./Contacts.css";

function Contacts() {
  return (
    <div className="Contacts">
      <p>Contacts View</p>
    </div>
  );
}

export default Contacts;
