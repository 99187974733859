import React, { useState } from "react";
import "./SText.css";

function SText(props: { text?: string; blinking?: boolean; size?: number }) {
  //@todo : mobile version
  return (
    <p className="st-text" style={{ fontSize: props.size ? props.size : 14 }}>
      {props.text}
      {props.blinking && <span>_</span>}
    </p>
  );
}

export default SText;
