import React from "react";
import { Switch, Route } from "react-router-dom";
import Contacts from "../views/contacts/Contacts";
import HomePage from "../views/homepage/Homepage";
import Menu from "../views/menu/Menu";

function StrideRouter() {
  return (
    <Switch>
      <Route path="/menu">
        <Menu />
      </Route>
      <Route path="/contacts">
        <Contacts />
      </Route>
      {/* Lasciare per ultimo */}
      <Route path="/">
        <HomePage />
      </Route>
    </Switch>
  );
}

export default StrideRouter;
