import React, { useState } from "react";
import DataManager, { ICategory } from "../../utils/DataManager";
import "./MenuItem.css";
import Image from "react-bootstrap/Image";
import SText from "../text/SText";

function MenuItem(props: { item: ICategory }) {
  //@todo : mobile version
  return (
    <div className="menuItem">
      <Image
        src={`./assets/${props.item.images[0]}.png`}
        className={"imageMenuItem"}
        rounded
      />
      <SText text={props.item.defaultName} blinking size={100} />

      <div className={"leftBlink"} />
      <div className={"rightBlink"} />
    </div>
  );
}

export default MenuItem;
