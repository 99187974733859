import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import DataManager from "../../utils/DataManager";
import { Container, Row, Col } from "react-bootstrap";
import Menu from "../../views/menu/Menu";
import Hamburger from "../hamburger/Hamburger";
import Image from "react-bootstrap/Image";
import SText from "../text/SText";

function Header() {
  //@todo : mobile version
  return (
    <Container fluid>
      <Row className="headerRow">
        <Col>
          {/*<Link to="/menu">Menu</Link>*/}
          <Hamburger />
        </Col>
        <Col>
          <Link to="/menu">
            <SText text="Manifesto" blinking />
          </Link>
        </Col>
        <Col>
          <Link to="/">
            <SText text="stride" blinking />
          </Link>
        </Col>
        <Col>
          <Link to="/contacts">
            <SText text="Contact us" blinking />
          </Link>
        </Col>
        <Col>
          {/*<Link to="/chat">Chat</Link>*/}
          <Image
            src="./assets/pittogramma.png"
            className={"imageStride"}
            rounded
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Header;
