import React, { useRef, useState } from "react";
import "./Menu.css";
import {
  animated,
  useTransition,
  useSpring,
  useChain,
  config,
} from "react-spring";

function Menu() {
  /*const [showMenu, setShowMenu] = useState(true);
  const toggleMenu = () => setShowMenu(!showMenu);

  // Manifsto | Contact Us | Join Us | Our Quirks - Blog
  const menuItems = [
    { name: "Manifesto", link: "/manifesto" },
    { name: "Contact Us", link: "/contacts" },
    { name: "Join Us", link: "/join-us" },
    { name: "Our Quirks - Blog", link: "/blog" },
  ];

  // Set refs - required for useChain
  const navRef = useRef();
  const liRef = useRef();

  // Setup animation for nav element
  const springProps = useSpring({
    config: config.default,
    from: { width: "0%" },
    to: { width: showMenu ? "100%" : "0%" },
  });

  // Setup animations for nav items
  const liTransitions = useTransition(
    showMenu ? menuItems : [],
    (item: { name: any }) => item.name,
    {
      ref: liRef,
      trail: 400 / menuItems.length,
      from: { opacity: 0, transform: "translate3d(40px,0,0)" },
      enter: { opacity: 1, transform: "translate3d(0,0,0)" },
      leave: { opacity: 0, transform: "translate3d(40px,0,0)" },
    }
  );

  // On showMenu, start with nav animationm then nav items
  useChain(showMenu ? [navRef, liRef] : [liRef, navRef], [
    0,
    showMenu ? 0.4 : 0.6,
  ]);*/

  return (
    <div className="Menu">
      <p>Menu View</p>
    </div>
  );
}

export default Menu;
