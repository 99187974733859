import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/header/Header";
import StrideRouter from "./routes/StrideRouter";
import Image from "react-bootstrap/Image";

function App() {
  const [headerVisible, setHeaderVisible] = useState(true);

  return (
    <Router>
      <div>
        <Image className="main" src={"./assets/bio_bg.jpg"} />
        <Header />
        <StrideRouter />
      </div>
    </Router>
  );
}

export default App;
